import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import Hero from "../../components/Hero";

const OffsetImage = styled.div`
   margin-top: -32px;
   margin-left: -18px;
   
   @media(min-width: 768px) {
      margin-left: 0
   }
`;

const LandingHero = () => {
  return (
    <Hero
      title={
        <FormattedMessage
          id="hero.title"
          defaultMessage="We specialize in data"
        />
      }
      subtitle={
        <FormattedMessage
          id="hero.subtitle"
          defaultMessage="
          Our focus is <b>integration</b>, <b>processing</b> and <b>visualization</b> of data on the web."
          values={{
            b: (...chunks) => <b>{chunks}</b>
          }}
        />
      }
      image={
        <OffsetImage>
          <img
            src="https://app.eyk.is/media/hexmap_of_48_hours_of_quakes.svg"
            alt="Number of seismic events near Iceland in the last 48 hours"
          />
        </OffsetImage>
      }
    />
  );
};

export default LandingHero;
