import styled from "styled-components";

const Margin = styled.div`
  margin-top: ${props => (props.top ? `${props.top}` : undefined)};
  margin-bottom: ${props => (props.bottom ? `${props.bottom}` : undefined)};
  margin-left: ${props => (props.left ? `${props.left}` : undefined)};
  margin-right: ${props => (props.right ? `${props.right}` : undefined)};
  & > :not(:first-child) {
    margin-left: ${props => props.betweenChildren};
  }
`;

export default Margin;
