import React from "react";
import styled from "styled-components";
import Flex from "./Flex";
import {Col, Row} from "antd";
import Header from "./Header";
import {useLocation} from "react-router-dom";
import VerticalSplitTitle from "./VerticalSplitTitle";
import {GUTTER, MAX_WIDTH_PX} from "../constants";
import Padding from "./Padding";

const Container = styled(Flex.Column)`
  padding: ${GUTTER}px;
  padding-bottom: 0;
  width: 100%;
`;

const OverflowRightCol = styled(Col)`
  display: flex;
  overflow: visible;
  justify-content: flex-start;
`;

const OffsetContainer = styled.div`
  margin-bottom: -150px;
`;

const Hero = ({image, title, subtitle}) => {
  const location = useLocation();
  return (
    <Container
      justifyContent="center"
      alignItems="center"
      className="alternate-background"
    >
      <Header key="header" location={location}/>
      <Padding.Vertical px="64"/>
      <VerticalSplitTitle text={subtitle} title={title}
                          style={{maxWidth: `${MAX_WIDTH_PX}px`}}/>
      <Padding.Vertical px="64"/>
      <Row style={{ width: "100%", justifyContent: "center"}}>
        <OverflowRightCol >
          <OffsetContainer>{image}</OffsetContainer>
        </OverflowRightCol>
      </Row>
    </Container>
  );
};

export default Hero;
