import {ConfigProvider} from "antd";

import antdIs from "antd/lib/locale-provider/is_IS";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {IntlProvider} from "react-intl";

import isTranslations from "../translations/locales/is.json";

import {DEFAULT_LANGUAGE} from "../constants";

export const LocaleContext = React.createContext();

const LanguageProvider = ({children}) => {
  const messages = {
    en: {}, // use default message
    is: isTranslations
  };

  const [locale, setLocale] = useState(navigator.language === "is" ? "is" : DEFAULT_LANGUAGE);
  const otherLocale = locale === "is" ? "en" : "is";
  return (
    <LocaleContext.Provider
      value={{
        locale,
        otherLocale,
        toggleLocale: () => setLocale(otherLocale)
      }}
    >
      <ConfigProvider locale={locale === "is" ? antdIs : undefined}>
        <IntlProvider
          textComponent="span"
          locale={locale}
          defaultLocale="en"
          key={locale}
          messages={messages[locale]}
        >
          {React.Children.only(children)}
        </IntlProvider>
      </ConfigProvider>
    </LocaleContext.Provider>
  );
};

LanguageProvider.propTypes = {
  children: PropTypes.element.isRequired
};

export default LanguageProvider;
