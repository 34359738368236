import styled from "styled-components";
import {Col, Row} from "antd";
import {GUTTER} from "../constants";
import React from "react";
import Flex from "./Flex";

const borderStyle = "1px solid #CA4D27";

const Text = styled.text`
  line-height: 150%;
  letter-spacing: 1px;
  font-weight: 500;
`;

const Title = styled(Text)`
  text-decoration: none;
  font-size: 32px;
`;

const Subtitle = styled(Text)`
  font-size: 16px;
`;

const ReversibleRow = styled(Row)`
  @media(min-width: 768px) {
    flex-direction: ${props => props.reversed ? "row-reverse" : "row"};
  }
`;

const Column = styled(Col)`
  display: flex;
  align-items: center;
  border-left: 2px solid #ca4d27;
  
  @media(min-width: 768px) {
    border-right: ${props => props.reversed ? "unset" : borderStyle};
    border-left: ${props => props.reversed ? borderStyle : "unset"};
    justify-content: ${props => props.reversed ? "flex-start" : "flex-end"};
    text-align: ${props => props.reversed ? "left" : "right"};
  }
`;

const RightColumn = styled(Column)`
  @media(min-width: 768px) {
    border-right: ${props => props.reversed ? borderStyle : "unset"};
    border-left: ${props => props.reversed ? "unset" : borderStyle};
    justify-content: ${props => props.reversed ? "flex-end" : "flex-start"};
    text-align: ${props => props.reversed ? "right" : "left"};
  }
`;

const VerticalSplitTitle = ({title, text, reversed, ...props}) => (
  <Flex justifyContent="center">
    <ReversibleRow gutter={GUTTER} reversed={reversed} {...props}>
      <Column xs={24} md={12} reversed={reversed}>
        <Title>{title}</Title>
      </Column>
      <RightColumn xs={24} md={12} reversed={reversed}>
        <Subtitle>{text}</Subtitle>
      </RightColumn>
    </ReversibleRow>
  </Flex>
)

VerticalSplitTitle.defaultProps = {
  style: {}
}

export default VerticalSplitTitle