import {useIntl} from "react-intl";

import {map} from "ramda";

const useTranslations = translations => {
  const intl = useIntl();
  return map(message => (variables = {}) =>
    intl.formatMessage(message, variables)
  )(translations);
};

export default useTranslations;