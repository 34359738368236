import styled from "styled-components";
import React from "react";
import ScrollSpyable from "./ScrollSpyable";

import Flex from "./Flex";
import BigText from "./BigText";
import Separator from "./Separator";
import {GUTTER, MAX_WIDTH_PX} from "../constants";
import isNotNil from "../utils/isNotNil";
import ConditionalWrapper from "./ConditionalWrapper";

export const SectionContainer = styled(Flex.Column)`
  width: 100%;
  padding: 100px ${GUTTER}px;
`;

const SectionTitle = styled(BigText)`
  font-size: 32px;
`;

const SectionBody = styled.div`
  width: 100%;
  max-width: ${props => props.maxWidth ? props.maxWidth : MAX_WIDTH_PX + "px"};
`;

const Section = ({
  id,
  title,
  path,
  children,
  className,
  maxWidth,
  reverse,
  ...rest
}) => {
  return (
    <ConditionalWrapper
      condition={isNotNil(id)}
      wrapper={children => (
        <ScrollSpyable hash={id} id={id}>
          {children}
        </ScrollSpyable>
      )}
    >
      <SectionContainer
        reverse={reverse}
        alignItems="center"
        className={`${className} alternate-background`}
        {...rest}
      >
        {title && [
          <SectionTitle key="title">{title}</SectionTitle>,
          <Separator key="separator"/>
        ]}
        <SectionBody maxWidth={maxWidth}>{children}</SectionBody>
      </SectionContainer>
    </ConditionalWrapper>
  );
};

export default Section;
