import Section from "../Section";
import {FormattedMessage} from "react-intl";
import PortfolioLink, {PortfolioWall} from "../PortfolioLink";
import ReactLogo from "./logos/react.svg";
import DjangoLogo from "./logos/django.webp";
import DjangoLogoBackup from "./logos/django.png";
import PostgreSQLLogo from "./logos/postgresql.webp";
import PostgreSQLLogoBackup from "./logos/postgresql.png";
import CentosLogo from "./logos/centos.webp";
import CentosLogoBackup from "./logos/centos.png";
import GraphQLLogo from "./logos/graphql.webp";
import GraphQLLogoBackup from "./logos/graphql.png";
import PythonLogo from "./logos/python.webp";
import PythonLogoBackup from "./logos/python.png";
import RabbitMQLogo from "./logos/rabbitmq.webp";
import RabbitMQLogoBackup from "./logos/rabbitmq.png";
import AnsibleLogo from "./logos/ansible.webp";
import AnsibleLogoBackup from "./logos/ansible.png";
import React from "react";
import VerticalSplitTitle from "../VerticalSplitTitle";
import Padding from "../Padding";
import {MAX_WIDTH_PX} from "../../constants";

export default () => <Section
  id="technology"
  maxWidth={"1200px"}
>
  <VerticalSplitTitle
    style={{maxWidth: `${MAX_WIDTH_PX}px`}}
    title={
      <FormattedMessage id="technology.title" defaultMessage="Technology"/>
    }
    text={
      <FormattedMessage
        id="technology.text"
        defaultMessage="Modern web development requires a diverse toolset. Here's a selection of the tools we use"
      />
    }
  />
  <Padding.Vertical px="48"/>
  <PortfolioWall style={{flexDirection: "row"}}>
    <PortfolioLink
      key="react"
      logo={ReactLogo}
      link="https://reactjs.org/"
      title="React"
      subtitle={
        <FormattedMessage
          id="technology.react.title"
          defaultMessage="User interfaces"
        />
      }
    />
    <PortfolioLink
      key="django"
      logo={DjangoLogo}
      backupLogo={DjangoLogoBackup}
      link="https://www.djangoproject.com/"
      title="Django"
      subtitle={
        <FormattedMessage
          id="technology.django.title"
          defaultMessage="Backend services"
        />
      }
    />
    <PortfolioLink
      key="postgresql"
      logo={PostgreSQLLogo}
      backupLogo={PostgreSQLLogoBackup}
      link="https://www.postgresql.org/"
      title="PostgreSQL"
      subtitle={
        <FormattedMessage
          id="technology.postgresql.title"
          defaultMessage="Data storage"
        />
      }
    />
    <PortfolioLink
      key="centos"
      logo={CentosLogo}
      backupLogo={CentosLogoBackup}
      link="https://www.centos.org/"
      title="CentOS"
      subtitle={
        <FormattedMessage
          id="technology.centos.title"
          defaultMessage="Server operation"
        />
      }
    />
    <PortfolioLink
      key="graphql"
      logo={GraphQLLogo}
      backupLogo={GraphQLLogoBackup}
      link="https://graphql.org/"
      title="GraphQL"
      subtitle={
        <FormattedMessage
          id="technology.graphql.title"
          defaultMessage="Data API"
        />
      }
    />
    <PortfolioLink
      key="python"
      logo={PythonLogo}
      backupLogo={PythonLogoBackup}
      link="https://www.python.org/"
      title="Python"
      subtitle={
        <FormattedMessage
          id="technology.python.title"
          defaultMessage="Data Processing"
        />
      }
    />
    <PortfolioLink
      key="rabbitmq"
      logo={RabbitMQLogo}
      backupLogo={RabbitMQLogoBackup}
      link="https://www.rabbitmq.com/"
      title="RabbitMQ"
      subtitle={
        <FormattedMessage
          id="technology.rabbitmq.title"
          defaultMessage="Data processing queue management"
        />
      }
    />
    <PortfolioLink
      key="ansible"
      logo={AnsibleLogo}
      backupLogo={AnsibleLogoBackup}
      link="https://www.ansible.com/"
      title="Ansible"
      subtitle={
        <FormattedMessage
          id="technology.ansible.title"
          defaultMessage="Infrastructure automation"
        />
      }
    />
  </PortfolioWall>
</Section>
