import React from "react";
import {BrowserRouter as Router, useLocation} from "react-router-dom";
import AnimatedSwitch from "./components/AnimatedSwitch";
import "./App.css";
import Landing from "./pages/Landing";
import SampleCollection from "./pages/SampleCollection";
import Hvammsvirkjun from "./pages/Hvammsvirkjun";
import QuakeDashboard from "./pages/QuakeDashboard";
import Page from "./components/Page";
import LanguageProvider from "./components/LanguageProvider";

const SPA = () => {
  const location = useLocation();
  return [
    <AnimatedSwitch key="switch" location={location}>
      <Page path="/projects/sample-collection">
        <SampleCollection />
      </Page>
      <Page path="/projects/hvammsvirkjun">
        <Hvammsvirkjun />
      </Page>
      <Page path="/projects/quake-dashboard">
        <QuakeDashboard />
      </Page>
      <Page path="/" alternateChildDirection={false}>
        <Landing/>
      </Page>
    </AnimatedSwitch>
  ];
};

function App() {
  return (
    <LanguageProvider>
      <Router>
        <SPA/>
      </Router>
    </LanguageProvider>
  );
}

export default App;
