import React from "react";
import {FormattedMessage} from "react-intl";
import Hero from "../../components/Hero";
import hero from "./screenshots/hvammsvirkjun1200.webp";
import visibility from "./screenshots/visibility.webp";
import visibilityBackup from "./screenshots/visibility.png";
import regionalEffects from "./screenshots/RegionalEffects.webp";
import regionalEffectsBackup from "./screenshots/RegionalEffects.png";
import baseLayer from "./screenshots/baseLayer.webp";
import baseLayerBackup from "./screenshots/baseLayer.png";
import protection from "./screenshots/protection.webp";
import protectionBackup from "./screenshots/protection.png";
import material from "./screenshots/Material.webp";
import materialBackup from "./screenshots/Material.png";
import landUsage from "./screenshots/LandUsage.webp";
import landUsageBackup from "./screenshots/LandUsage.png";
import visualImpactVideo from "./screenshots/hvammsvirkjunB&A.mp4";
import Screenshot from "../../components/Screenshot";
import Section from "../../components/Section";
import Feature from "../../components/Feature";
import {GUTTER} from "../../constants";
import {Col, Row, Timeline} from "antd";
import styled from "styled-components";
import heroBackup from "./screenshots/hvammsvirkjun1200.png";
import VerticalSplitTitle from "../../components/VerticalSplitTitle";
import Padding from "../../components/Padding";
// import ContactUsButton from "../../components/ContactUsButton";

const Text = styled.p`
  line-height: 150%;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 16px;
`;

const LegendItemContainer = styled.div`
  display: flex;
  align-items: center;
  height: 30px;

  & span {
    flex: 1 1 0;
    font-size: 0.8rem;
    line-height: 0.8rem;
  }
`;

const LegendShape = styled.div`
  background-image: ${props => `url(${props.image})`};
`;
const LegendCircle = styled(LegendShape)`
  background-size: contain;
  width: 15px;
  height: 15px;
  margin-top: 2px;
  margin-left: 2.5px;
  margin-right: 9px;
  border: 1px solid rgb(148, 148, 148);
  border-radius: 50%;
`;
const LegendLine = styled(LegendShape)`
  background-size: 20px;
  width: 20px;
  height: 20px;
  margin-right: 6px;
  background-repeat: no-repeat;
`;

const LegendSquare = styled(LegendShape)`
  width: 20px;
  height: 20px;
  margin-right: 6px;
  background-position: center center;
  background-repeat: no-repeat;
`;

const LegendItem = ({text, image, circle, line, square}) => (
  <LegendItemContainer>
    {circle && <LegendCircle image={image}/>}
    {line && <LegendLine image={image}/>}
    {square && <LegendSquare image={image}/>}
    <span> {text} </span>
  </LegendItemContainer>
);

const Index = () => {
  return (
    <React.Fragment>
      <Hero
        image={<Screenshot src={hero} backupSrc={heroBackup} width="1200px"/>}
        title={
          <FormattedMessage
            id="hvammsvirkjun.hero.title"
            defaultMessage="Hvammsvirkjun"
          />
        }
        subtitle={
          <FormattedMessage
            id="hvammsvirkjun.hero.subtitle"
            defaultMessage="In this project we produced a map module which displays the
            estimated environmental impact of Hvammsvirkjun, a proposed hydroelectric
            power plant"
          />
        }
      />
      <Section
        id="about-this-project"
        className="topology"
        title={
          <FormattedMessage
            id="hvammsvirkjun.about.title"
            defaultMessage="About this project"
          />
        }
      >
        <Row gutter={[GUTTER, GUTTER]}>
          <Col md={12} xs={24}>
            <Text>
              <FormattedMessage
                id="hvammsvirkjun.aboutText"
                defaultMessage="<p>During this project we
                worked with Efla, Mannvit, Jónsson og Le´Macks and Skapalón and together we released {link}
                mid year 2018.</p>
                Our role was to make the spatial data from Efla's static environmental
                report accessible as an interactive web map. We worked with Mannvit
                Engineering in creating a custom base map to present the layers over and
                finally integrated the results into Skapalón's CMS solution.
                "
                values={{
                  p: (...chunks) => <p>{chunks}</p>,
                  link: (
                    <a
                      href="https://hvammur.landsvirkjun.is"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      hvammur.landsvirkjun.is
                    </a>
                  )
                }}
              />
            </Text>
            {/*<ContactUsButton*/}
            {/*  subject="Hugfimi.is: Interactive web map"*/}
            {/*  body="I'd like to hear more about your interactive web map services. Are you available for a call?"*/}
            {/*  colorless={false}*/}
            {/*>*/}
            {/*  Contact us*/}
            {/*</ContactUsButton>*/}
          </Col>
          <Col md={12} xs={24}>
            <Timeline style={{marginBottom: "-20px"}}>
              <Timeline.Item
                // label="PostgreSQL & PostGIS"
              >
                Shapefiles containing shapes and locations for each layer were
                imported into a PostgreSQL database with a PostGIS extension
              </Timeline.Item>
              <Timeline.Item
                // label="Mapnik & GDAL"
              >
                Using Mapnik and GDAL we gave each layer its own styling.
                Opacity, line thickness and shapes were key to making each layer
                legible and fit the colorscheme of the surrounding page.
              </Timeline.Item>
              <Timeline.Item
                // label="Leaflet & JQuery"
              >
                The layers were then managed and presented using Leaflet and
                JQuery.
              </Timeline.Item>
            </Timeline>
          </Col>
        </Row>
      </Section>
      <Section
        id="visual-impact"
        maxWidth="1200px"
      >
        <VerticalSplitTitle
          title={
            <FormattedMessage
              id="hvammsvirkjun.visualImpact.title"
              defaultMessage="Visual Impact"
            />
          }
          text={
            <FormattedMessage
              id="hvammsvirkjun.visualImpact.text"
              defaultMessage="Renderings of the construction in it's environment, as
                  seen from 32 different locations, were generated by Mannvit. We display
                  these locations and allow users to view the visual impact of the project"
            />
          }
        />
        <Padding.Vertical px="48"/>
        <video
          width="1200"
          height="auto"
          autoPlay
          loop
          controls={true}
          style={{
            boxShadow: "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
            borderRadius: "8px"
          }}
        >
          <source src={visualImpactVideo} type="video/mp4"/>
        </video>
      </Section>
      <Section id="layers"
               className="topology"
      >
        <VerticalSplitTitle
          title={
            <FormattedMessage
              id="hvammsvirkjun.layers.title"
              defaultMessage="Layers"
            />
          }
          text={
            <FormattedMessage
              id="hvammsvirkjun.layers.title.text"
              defaultMessage="Each layer displays information about the construction or its surroundings."
            />
          }
        />
        <Padding.Vertical px="48"/>
        <Feature.Focus
          reverse
          image={
            <Screenshot
              width="800px"
              src={baseLayer}
              backupSrc={baseLayerBackup}
            />
          }
          gutter={GUTTER}
          title={
            <FormattedMessage
              id="hvammsvirkjun.baseLayer.title"
              defaultMessage="Construction and base layer"
            />
          }
          text={
            <div>
              <FormattedMessage
                id="hvammsvirkjun.baseLayer.text"
                defaultMessage="The construction along with changes in infrastructure
                and water surface are presented on a custom map layer with hand picked place names."
              />
              <Row style={{padding: "16px 0 0 16px"}} gutter={[4, 4]}>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    text="Construction"
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/mannvirki.png"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    text="Water surface"
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/lon.png"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    line
                    text="Dam"
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/stifla.png"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    line
                    text="Fish ladder"
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/fiskistigi.png"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    line
                    text="Pressure pipe/tunnel"
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/pipur.png"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    line
                    text="Fixed/changed road"
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/breyttur_vegur.png"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    line
                    text="Alteration of transmission line"
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/breytt_haspennulina.png"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    line
                    text="Current transmission lines"
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/haspennulina.png"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    line
                    text="New road and bridge over Þjórsá"
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/vegur_yfir_thjorsa.png"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    line
                    text="New road"
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/vegir.png"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    square
                    text="Family housing"
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/square-gray.png"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    square
                    text="Hotels and inns"
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/square-red.png"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    square
                    text="Summer cottages"
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/square-orange.png"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    square
                    text="Stores and services"
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/square-green.png"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    square
                    text="Culture venues"
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/square-green.png"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    square
                    text="Churches"
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/place_of_worship.png"
                  />
                </Col>
              </Row>
            </div>
          }
        />
        <Padding.Vertical px="96"/>
        <Feature.Focus
          image={
            <Screenshot
              width="800px"
              src={visibility}
              backupSrc={visibilityBackup}
            />
          }
          gutter={GUTTER}
          title={
            <FormattedMessage
              id="hvammsvirkjun.visibility.title"
              defaultMessage="Construction visibility"
            />
          }
          text={
            <div>
              <FormattedMessage
                id="hvammsvirkjun.visibility.text"
                defaultMessage="This layer portrays the visibility of the construction
                in a 5 km radius. The construction is visible from any colored area and the
                color denotes the distance from that point to the construction."
              />
              <Row style={{padding: "16px 0 0 16px"}} gutter={[4, 4]}>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    text="0-1 km distance"
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/synileiki1.png"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    text="1-2 km distance"
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/synileiki2.png"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    text="2-3 km distance"
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/synileiki3.png"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    text="3-4 km distance"
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/synileiki4.png"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    text="4-5 km distance"
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/synileiki5.png"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    line
                    text="5 km distance"
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/bufferlina.png"
                  />
                </Col>
              </Row>
            </div>
          }
        />
        <Padding.Vertical px="96"/>
        <Feature.Focus
          reverse
          image={
            <Screenshot
              width="800px"
              src={regionalEffects}
              backupSrc={regionalEffectsBackup}
            />
          }
          gutter={GUTTER}
          title={
            <FormattedMessage
              id="hvammsvirkjun.regionalEffects.title"
              defaultMessage="Effects on nearby regions"
            />
          }
          text={
            <div>
              <FormattedMessage
                id="hvammsvirkjun.regionalEffects.text"
                defaultMessage="This layer displays nearby regions and the effect the
                 construction would have on them. The effects on each region are
                 extracted from a written report and displayed in the sidebar when a
                 region is clicked."
              />
              <Row style={{padding: "16px 0 0 16px"}} gutter={[4, 4]}>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/landslagsheildir/arnes_og_nupar.png"
                    text="Árnes og Núpar"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/landslagsheildir/fossnes.png"
                    text="Fossnes"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/landslagsheildir/hagafjall_og_gaukshofdi.png"
                    text="Hagafjall og Gaukshöfði"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/landslagsheildir/holt.png"
                    text="Holt"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/landslagsheildir/myrarskogur.png"
                    text="Mýrarskógur"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/landslagsheildir/þjorsa_bakkar_hennar_og_holmar.png"
                    text="Þjórsá, bakkar hennar og hólmar"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/landslagsheildir/þjorsarhraun.png"
                    text="Þjórsárhraun"
                  />
                </Col>
              </Row>
            </div>
          }
        />
        <Padding.Vertical px="96"/>
        <Feature.Focus
          image={
            <Screenshot
              width="800px"
              src={material}
              backupSrc={materialBackup}
            />
          }
          gutter={GUTTER}
          title={
            <FormattedMessage
              id="hvammsvirkjun.material.title"
              defaultMessage="Material mounding and extraction"
            />
          }
          text={
            <div>
              <FormattedMessage
                id="hvammsvirkjun.material.text"
                defaultMessage="This layer shows areas where material will be extracted or stored during and after the construction."
              />
              <Row style={{padding: "16px 0 0 16px"}} gutter={[4, 4]}>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/efnistaka/efnistaka-fokmold.png"
                    text="Material extraction - Dirt"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/efnistaka/efnistaka-grjot.png"
                    text="Material extraction - Rocks"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/efnistaka/efnistaka-sandurmol.png"
                    text="Material extraction - Sand/gravel"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/efnistaka/haugsetning-framkvaemdatimi.png"
                    text="Mounding - Construction time"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/efnistaka/haugsetning-rekstrartimi.png"
                    text="Mounding - Operating time"
                  />
                </Col>
              </Row>
            </div>
          }
        />
        <Padding.Vertical px="96"/>
        <Feature.Focus
          reverse
          image={
            <Screenshot
              width="800px"
              src={landUsage}
              backupSrc={landUsageBackup}
            />
          }
          gutter={GUTTER}
          title={
            <FormattedMessage
              id="hvammsvirkjun.landUsage.title"
              defaultMessage="Utilization of land"
            />
          }
          text={
            <div>
              <FormattedMessage
                id="hvammsvirkjun.landUsage.text"
                defaultMessage="This layer shows usage or intended use of the nearby
                 land along with water protection zones and other protected areas."
              />
              <Row style={{padding: "16px 0 0 16px"}} gutter={[4, 4]}>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/landnotkun/svaedi_fyrir_fristundabyggd.png"
                    text="Designated for leisure"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/landnotkun/blondud_landnotkun.png"
                    text="Mixed land use"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/landnotkun/fristundabyggd.png"
                    text="Leisure area"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/landnotkun/idnadarsvaedi.png"
                    text="Industrial area"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/landnotkun/opin_svaedi.png"
                    text="Open area"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/landnotkun/ibuabyggd.png"
                    text="Residential area"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/landnotkun/svaedi_fyrir_thjonustustofnanir.png"
                    text="Service institution area"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/landnotkun/efnistaka_haugsvaedi.png"
                    text="Material extraction/mounding"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/landnotkun/verslunar_og_thjonustusvaedi.png"
                    text="Retail and service area"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/landnotkun/thjodminjaverndarsvaedi.png"
                    text="National heritage area"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/landnotkun/hverfisvernd.png"
                    text="Environmental protection"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/landnotkun/vatnsvernd_brunnsvaedi.png"
                    text="Water protection - Wellhead zone"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/landnotkun/vatnsvernd_grannsvaedi.png"
                    text="Water protection - Inner zone"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/landnotkun/vatnsvernd_fjarsvaedi.png"
                    text="Water protection - Outer zone"
                  />
                </Col>
              </Row>
            </div>
          }
        />
        <Padding.Vertical px="96"/>
        <Feature.Focus
          image={
            <Screenshot
              width="800px"
              src={protection}
              backupSrc={protectionBackup}
            />
          }
          gutter={GUTTER}
          title={
            <FormattedMessage
              id="hvammsvirkjun.protection.title"
              defaultMessage="Protected areas"
            />
          }
          text={
            <div>
              <FormattedMessage
                id="hvammsvirkjun.protection.text"
                defaultMessage="This layer displays protected areas in more detail.
                 Special attention is given to Minnanúpshólmi, the green islet with the
                  green marker, because of its rare vegetation. Short text along with a
                   photo is displayed when clicking the green marker "
              />
              <Row style={{padding: "16px 0 0 16px"}} gutter={[4, 4]}>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/verndarsvaedi/fridlyst_svaedi.png"
                    text="Protected area"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/verndarsvaedi/hatt_minjagildi.png"
                    text="Protected archaeological sites"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/verndarsvaedi/adrar_fornminjar.png"
                    text="Other archaeological sites"
                  />
                </Col>
                <Col xs={24} md={12}>
                  <LegendItem
                    circle
                    image="http://hvammur.landsvirkjun.is/Content/lv_arsskyrsla/img/legends/landnotkun/hverfisvernd.png"
                    text="Environmental protection"
                  />
                </Col>
              </Row>
            </div>
          }
        />
      </Section>
    </React.Fragment>
  );
};

export default Index;
