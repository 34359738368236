import {Button} from "antd";
import styled from "styled-components";
import React from "react";
import {CONTACT_US_URL} from "../constants";

export const composeMailToUrl = ({subject, body}) =>
  `mailto:${CONTACT_US_URL}?subject=Hugfimi.is: ${subject}${body ? "&body=" + body : ""}`;

const ColorlessButton = styled(Button)`
  color: ${props => props.colorless && "unset"};
  padding: ${props => props.type === "link" && 0};
`;

const ContactUsButton = ({
  colorless,
  subject,
  body,
  type,
  children,
  ...rest
}) => {
  const mailToUrl = composeMailToUrl({ subject, body });
  return (
    <ColorlessButton
      type={type}
      colorless={colorless}
      onClick={() => (window.location.href = mailToUrl)}
      {...rest}
    >
      {children}
    </ColorlessButton>
  );
};

export default ContactUsButton;
