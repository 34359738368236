export default onImagesLoadedFn => {
  setTimeout(() => {
    const imgs = document.images;
    const len = imgs.length;
    let counter = 0;

    const incrementCounter = () => {
      counter++;

      if (counter === len) {
        onImagesLoadedFn();
      }
    };

    [].forEach.call(imgs, img => {
      if (img.complete) {
        incrementCounter();
      } else {
        img.addEventListener("load", incrementCounter, false);
      }
    });
  });
};
