import Section from "../Section";
import {FormattedMessage} from "react-intl";
import PortfolioLink, {PortfolioWall} from "../PortfolioLink";
import React from "react";
import HSLogo from "./logos/hs.svg";
import LVLogo from "./logos/lv.svg";
import MVLogo from "./logos/mv.svg";
import ORLogo from "./logos/or.webp";
import ORLogoBackup from "./logos/or.png";
import PLLogo from "./logos/pl.webp";
import PLLogoBackup from "./logos/pl.png";
import CFLogo from "./logos/carbfix.svg";
import VerticalSplitTitle from "../VerticalSplitTitle";
import {MAX_WIDTH_PX} from "../../constants";
import Padding from "../Padding";

export default props => <Section
  id="clients"
  maxWidth={"1200px"}
  {...props}
>
  <VerticalSplitTitle
    reversed
    style={{maxWidth: `${MAX_WIDTH_PX}px`}}
    title={
      <FormattedMessage id="clients.title" defaultMessage="Clients"/>
    }
    text={
      <FormattedMessage
        id="clients.text"
        defaultMessage="These are the people we've worked for, we couldn't be more grateful for them"
      />
    }
  />
  <Padding.Vertical px="48"/>
  <PortfolioWall style={{flexDirection: "row"}} sm={12} lg={8}>
    <PortfolioLink
      key="or"
      logo={ORLogo}
      backupLogo={ORLogoBackup}
      link="https://www.or.is/en/"
      title={<FormattedMessage id="clients.or" defaultMessage="Reykjavík Energy"/>}
    />
    <PortfolioLink
      key="lv"
      logo={LVLogo}
      link="https://www.landsvirkjun.com/"
      title="Landsvirkjun"
    />
    <PortfolioLink
      key="pl"
      logo={PLLogo}
      backupLogo={PLLogoBackup}
      link="http://purlucid.com/"
      title="PurLucid"
    />
    <PortfolioLink
      key="cf"
      logo={CFLogo}
      link="https://www.carbfix.com/"
      title="Carbfix"
    />
    <PortfolioLink
      key="hs"
      logo={HSLogo}
      link="https://www.hsorka.is/en/"
      title="HS Orka"
    />
    <PortfolioLink
      key="mv"
      logo={MVLogo}
      link="https://www.mannvit.com/"
      title={<FormattedMessage id="clients.mv" defaultMessage="Mannvit Engineering"/>}
    />
  </PortfolioWall>
</Section>
