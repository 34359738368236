import React from "react";
import {Link} from "react-router-dom";
import {Menu} from "antd";
import isNotNil from "../utils/isNotNil";
import headerLogoWebp from "../images/Hugfimi-fill-side.webp";
import headerLogo from "../images/Hugfimi-fill-side.png";
import styled from "styled-components";
import {defineMessages} from "react-intl";
import useTranslations from "../hooks/useTranslations";
import {isEmpty} from "ramda";
import {MAX_WIDTH_PX} from "../constants";
import Image from "./Image";

const HeaderContainer = styled.div`
  max-width: ${MAX_WIDTH_PX}px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  
  & .ant-menu {
    background-color: transparent;
    border-bottom: unset;
  }
`;

const PageHeader = ({links, sections, location}) => {
  const messages = useTranslations(
    defineMessages({
      servicesHeader: {
        id: "landing.header.services",
        defaultMessage: "Services"
      },
      productsHeader: {
        id: "landing.header.products",
        defaultMessage: "Products"
      },
      projectsHeader: {
        id: "landing.header.projects",
        defaultMessage: "Projects"
      },
      clientsHeader: {
        id: "landing.header.clients",
        defaultMessage: "Clients"
      },
      aboutUsHeader: {
        id: "landing.header.aboutUs",
        defaultMessage: "About us"
      },
      technologyHeader: {
        id: "landing.header.technology",
        defaultMessage: "Technology"
      }
    })
  );
  // const {otherLocale, toggleLocale} = useLocale();
  const selectedKeys = `${location.pathname}${location.hash}`;

  const defaultSections = [
    {
      path: "/",
      href: "services",
      title: messages.servicesHeader()
    },
    {
      path: "/",
      href: "products",
      title: messages.productsHeader()
    },
    {
      path: "/",
      href: "projects",
      title: messages.projectsHeader()
    },
    // {
    //   path: "/",
    //   href: "about-us",
    //   title: messages.aboutUsHeader()
    // },
    {
      path: "/",
      href: "technology",
      title: messages.technologyHeader()
    },
    {
      path: "/",
      href: "clients",
      title: messages.clientsHeader()
    }
  ];

  const sectionsToDisplay = isEmpty(sections) ? defaultSections : sections;
  return (
    <HeaderContainer>
      <Menu
        mode="horizontal"
        style={{lineHeight: "64px"}}
        selectedKeys={[selectedKeys]}
      >
        <Menu.Item key="/#">
          <Link to={"/#"}>
            <Image
              src={headerLogoWebp}
              backupSrc={headerLogo}
              height="32px"
              alt="hugfimi logo"
            />
          </Link>
        </Menu.Item>
      </Menu>
      <div style={{minWidth: "0px", flex: "auto"}}>
        <Menu
          mode="horizontal"
          style={{lineHeight: "64px", textAlign: "right"}}
          selectedKeys={[selectedKeys]}
        >
          {/*<Menu.Item disabled>|</Menu.Item>*/}
          {links.map(({path, title, subMenu, icon}) => {
            if (isNotNil(subMenu)) {
              return (
                <Menu.SubMenu
                  key={path}
                  title={
                    <span>
                      {icon && icon()}
                      {title}
                    </span>
                  }
                >
                  {subMenu.map(({path, title, text, icon}) => (
                    <Menu.Item key={path}>
                      {icon && icon()}
                      <Link to={path}>{title}</Link>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              );
            }
            return (
              <Menu.Item key={path}>
                {icon && icon()}
                <Link to={path}>{title}</Link>
              </Menu.Item>
            );
          })}
          {sectionsToDisplay.map(({path, href, title}) => {
            const sectionPath = `${path}#${href}`;
            return (
              <Menu.Item key={sectionPath}>
                <Link to={{pathname: path, hash: href}}>{title}</Link>
              </Menu.Item>
            );
          })}
          {/*<Menu.Item disabled>|</Menu.Item>*/}
          {/*<Menu.Item>*/}
          {/*  <ColorlessButton*/}
          {/*    type="link"*/}
          {/*    onClick={toggleLocale}*/}
          {/*    icon={<GlobalOutlined />}*/}
          {/*  >*/}
          {/*    {otherLocale.toUpperCase()}*/}
          {/*  </ColorlessButton>*/}
          {/*</Menu.Item>*/}
        </Menu>
      </div>
    </HeaderContainer>
  );
};

PageHeader.defaultProps = {
  links: [],
  sections: []
};

export default PageHeader;
