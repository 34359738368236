import Section from "../Section";
import {FormattedMessage} from "react-intl";
import React from "react";
import Feature from "../Feature";
import Hvammsvirkjun from "../../pages/Hvammsvirkjun/screenshots/hvammsvirkjun.webp";
import HvammsvirkjunBackup
  from "../../pages/Hvammsvirkjun/screenshots/hvammsvirkjun.png";
import Reinjection from "../../pages/QuakeDashboard/screenshots/reinjection.webp";
import ReinjectionBackup from "../../pages/QuakeDashboard/screenshots/reinjection.png";
import SampleCollection
  from "../../pages/SampleCollection/screenshots/sample-collection.webp";
import SampleCollectionBackup
  from "../../pages/SampleCollection/screenshots/sample-collection.png";
import Screenshot from "../Screenshot";
import Padding from "../Padding";
import {GUTTER} from "../../constants";
import VerticalSplitTitle from "../VerticalSplitTitle";
import {DashboardOutlined, EnvironmentOutlined, FormOutlined} from "@ant-design/icons";

const ProjectsSection = props => {
  return (
    <Section id="projects" {...props}>
      <VerticalSplitTitle
        reversed
        title={
          <FormattedMessage id="projects.title" defaultMessage="Projects"/>
        }
        text={
          <FormattedMessage
            id="projects.text"
            defaultMessage="Our experience is applicable to diverse problems. Here are some examples of what we've solved"
          />
        }
      />
      <Padding.Vertical px="48"/>
      <Feature.Focus
        image={<Screenshot width="800px" src={Hvammsvirkjun}
                           backupSrc={HvammsvirkjunBackup}/>}
        gutter={GUTTER}
        link="/projects/hvammsvirkjun"
        linkText={<FormattedMessage id="hvammsvirkjun.linkText"
                                    defaultMessage="View project story"/>}
        text={
          <FormattedMessage
            id="hvammsvirkjun.aboutText"
            defaultMessage="During this project we produced a map module which displays the estimated environmental impact of Hvammsvirkjun, a proposed hydroelectric power plant"
          />
        }
        title={
          <FormattedMessage
            id="hvammsvirkjun.title"
            defaultMessage="Environmental impact map"
          />
        }
        icon={() => <EnvironmentOutlined />}
      />
      <Padding.Vertical px="94"/>
      <Feature.Focus
        reverse
        link="/projects/quake-dashboard"
        image={<Screenshot width="800px" src={Reinjection}
                           backupSrc={ReinjectionBackup}/>}
        title={
          <FormattedMessage
            id="reinjection-quakes.title"
            defaultMessage="Seismic activity dashboard"
          />
        }
        icon={() => <DashboardOutlined />}
        text={
          <FormattedMessage
            id="reinjection-quakes.text"
            defaultMessage="
                      <p>
                        This dashboard was created to monitor seismic activity caused
                        by geothermal waste-water re-injection into the reservoir.
                      </p>"
            values={{
              p: (...chunks) => <p>{chunks}</p>
            }}
          />
        }
      />
      <Padding.Vertical px="94"/>
      <Feature.Focus
        link="/projects/sample-collection"
        image={<Screenshot width="800px" src={SampleCollection}
                           backupSrc={SampleCollectionBackup}/>}
        title={
          <FormattedMessage
            id="sample-collection.title"
            defaultMessage="Sample collection application"
          />
        }
        icon={() => <FormOutlined />}
        text={
          <FormattedMessage
            id="sample-collection.text"
            defaultMessage="
                      <p>
                        Collecting, analyzing and managing a large and dynamic sample
                        dataset can be complicated work. In this project we helped {or}
                        simplify their process by creating a data collection app
                        tailored to their needs.
                      </p>"
            values={{
              p: (...chunks) => <p>{chunks}</p>,
              or: (
                <a
                  href="https://or.is"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Reykjavík Energy
                </a>
              )
            }}
          />
        }
      />
    </Section>
  );
};

export default ProjectsSection;
