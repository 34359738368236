import React from "react";
import styled from "styled-components";
import {Col, Row} from "antd";
import Image from "./Image";

const Caption = styled.div`
  color: white;
  opacity: 0;
  display: block;
  background: rgba(67, 63, 64, 0.9);
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
  height: 100%;
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
`;

const PortfolioBox = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 650px;
  height: 250px;
  margin: 0 auto;
  & img {
    max-height: 150px;
    max-width: 80%;
    height:100%;
    width: 100%;
    object-fit: contain;
    margin: 50px;
  }

  &:hover ${Caption}, &:focus ${Caption}{
    opacity: 1;
  }
`;

const CaptionContent = styled.div`
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  & .project-category,
  & .project-name {
    font-family: "Open Sans", "Helvetica Neue", Arial, sans-serif;
    padding: 0 15px;
  }

  & .project-name {
    font-weight: 600;
    font-size: 18px;
  }

  & .project-category {
    font-size: 14px;
  }

  @media (min-width: 768px) {
    & .project-category {
      font-size: 16px;
    }
    & .project-name {
      font-size: 22px;
    }
  }
`;

const PortfolioLink = ({logo, backupLogo, title, subtitle}) => (
  <PortfolioBox>
    <Image src={logo} backupSrc={backupLogo} alt="tech logo"/>
    <Caption>
      <CaptionContent>
        <div className="project-name">{title}</div>
        <div className="project-category">{subtitle}</div>
      </CaptionContent>
    </Caption>
  </PortfolioBox>
);

export const PortfolioWall = ({children, ...rest}) => {
  return (
    <Row>
      {children.map(child => {
        return (
          <Col xs={24} sm={12} lg={6} key={child.key} {...rest}>
            {child}
          </Col>
        );
      })}
    </Row>
  );
};

export default PortfolioLink;
