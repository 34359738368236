import React from "react";
import {FormattedMessage} from "react-intl";
import Hero from "../../components/Hero";
import hero from "./screenshots/sample-collection.webp";
import heroBackup from "./screenshots/sample-collection.png";
import Screenshot from "../../components/Screenshot";
import WorkInProgressSection from "../../components/WorkInProgressSection";

const Index = () => {
  // const messages = useTranslations(defineMessages({
  //   demoSubject: {
  //     id: "sampleCollection.getDemo.subject",
  //     defaultMessage: "eyk platform"
  //   }
  // }))
  return (
    <React.Fragment>
      <Hero
        image={<Screenshot src={hero} backupSrc={heroBackup} width="1200px"/>}
        title={
          <FormattedMessage
            id="sampleCollection.hero.title"
            defaultMessage="Sample collection"
          />
        }
        subtitle={
          <FormattedMessage
            id="sampleCollection.hero.subtitle"
            defaultMessage="
                        Collecting, analyzing and managing a large and dynamic sample
                        dataset can be complicated work. In this project we helped {or}
                        simplify their process by creating a data collection app
                        tailored to their needs.
            "
            values={{
              or: (
                <a
                  href="https://or.is"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Reykjavík Energy
                </a>
              )
            }}
          />
        }
      />
      <WorkInProgressSection
        aboutBody={"Hi I'd like to know more about your eyk data management platform"}
        aboutSubject={"More about eyk platform"}
      />
      {/*<Features*/}
      {/*  title={*/}
      {/*    <FormattedMessage*/}
      {/*      id="sampleCollection.features.title"*/}
      {/*      defaultMessage="Features"*/}
      {/*    />*/}
      {/*  }*/}
      {/*  titleId="features"*/}
      {/*  featureList={features}*/}
      {/*/>*/}
      {/*<AccessManagementSection />*/}
      {/*<APISection />*/}
      {/*<CloudOrOnPremiseSection />*/}
      {/*<DemoSection demoSubject={messages.demoSubject()} />*/}
    </React.Fragment>
  );
};

export default Index;
