import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import {getBestAnchorGivenScrollLocation} from '../utils/scroll';
import debounce from "../utils/debounce";

const createId = ({hash}) => `___scroll-section___${hash || ''}`;

const getCurrentHash = () => decodeURI(window.location.hash.slice(1));

const getHash = ({manager}) => {
  const hash = getCurrentHash();
  return createId({hash});
}

const defaultConfig = {
  debounce: 100,
  keepLastAnchorHash: false,
  offset: 0,
  onSectionEnter: null
}

class Manager {
  constructor() {
    this.anchors = {};
    this.config = defaultConfig;
    this.ignoreNextScrollEvent = false;

    this.scrollHandler = debounce(this.handleScroll, ~~this.config.debounce);
  }

  addListeners = () => {
    window.addEventListener('scroll', this.scrollHandler, true);
  }

  removeListeners = () => {
    window.removeEventListener('scroll', this.scrollHandler, true);
  }

  configure = (config) => {
    this.config = {
      ...defaultConfig,
      ...config
    }
  }

  addAnchor = ({element, hash, id}) => {
    // if this is the first anchor, set up listeners
    if (Object.keys(this.anchors).length === 0) {
      this.addListeners();
    }

    this.anchors[id] = {
      id,
      component: element,
      hash
    };
  }

  removeAnchor = (id) => {
    delete this.anchors[id];
    // if this is the last anchor, remove listeners
    if (Object.keys(this.anchors).length === 0) {
      this.removeListeners();
    }
  }

  onSectionChange = (newAnchor, oldAnchor) => {
    const {onSectionEnter} = this.config;

    if (typeof onSectionEnter === 'function') {
      const nextState = newAnchor ? {...this.anchors[newAnchor], id: newAnchor} : {};
      const prevState = oldAnchor ? {...this.anchors[oldAnchor], id: oldAnchor} : {};
      onSectionEnter(nextState, prevState);
    }
  }

  handleScroll = (props, otherProps) => {
    if (this.ignoreNextScrollEvent) {
      this.ignoreNextScrollEvent = false;
      return;
    }
    const {offset, keepLastAnchorHash} = this.config;
    const nextAnchor = getBestAnchorGivenScrollLocation(this.anchors, -offset);
    const prevAnchor = getHash({manager: this});

    if (nextAnchor && prevAnchor !== nextAnchor) {
      this.forcedScrollChange = true;
      this.onSectionChange(nextAnchor, prevAnchor);

    } else if (!nextAnchor && !keepLastAnchorHash) {
      if (this.anchors[prevAnchor]) {
        this.onSectionChange(null, prevAnchor);
      }
    }
  }
}

export const ScrollSpyManager = new Manager()

export default class ScrollSpyable extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.array
    ]),
    hash: PropTypes.string,
    onEnter: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.hash = (props.hash || '').replace(/^#/, '') || props.children.ref || null;
    this.id = createId({hash: this.hash});
  }

  componentDidMount() {
    const element = ReactDOM.findDOMNode(this.refs[Object.keys(this.refs)[0]]);

    ScrollSpyManager.addAnchor({
      element,
      hash: this.hash,
      id: this.id,
    });
  }

  componentWillUnmount() {
    ScrollSpyManager.removeAnchor(this.id);
  }

  render() {
    const {children, hash, ...props} = this.props;

    if (Array.isArray(children)) {
      return (
        <div ref={this.id} {...props}>
          {React.Children.map(children, child => (
            React.cloneElement(child, {})
          ))}
        </div>
      )
    }

    return React.cloneElement(children, {
      ref: children.ref || this.id,
      ...props
    });

  }
}

ScrollSpyable.defaultProps = {};
