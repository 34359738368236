import React from "react";
import {FormattedMessage} from "react-intl";
import Section from "../../components/Section";
import Screenshot from "../../components/Screenshot";
import ClientsSection from "../../components/ClientsSection";
import EYKImage from "../EYKPlatform/screenshots/assets.webp";
import EYKImageBackup from "../EYKPlatform/screenshots/assets.png";
import EYKLogo from "../../images/eyk-borderless.webp";
import EYKLogoBackup from "../../images/eyk-borderless.png";
import TechnologySection from "../../components/TechnologySection";
import Feature from "../../components/Feature";
import {GUTTER} from "../../constants";
import ProjectSection from "../../components/ProjectsSection";
import LandingHero from "./LandingHero";
import Image from "../../components/Image";
import {Col, Row} from "antd";
import {
  DashboardOutlined,
  EnvironmentOutlined,
  FileSearchOutlined,
  FormOutlined
} from "@ant-design/icons";
import VerticalSplitTitle from "../../components/VerticalSplitTitle";
import Padding from "../../components/Padding";
import {composeMailToUrl} from "../../components/ContactUsButton";

const ServicesSection = () =>
  <Section className="topology" id="services">
    <VerticalSplitTitle
      reversed
      title={
        <FormattedMessage id="services.title" defaultMessage="Services"/>
      }
      text={
        <FormattedMessage
          id="services.text"
          defaultMessage="We're just the right team to help you with these things"
        />
      }
    />
    <Padding.Vertical px="48"/>
    <Row gutter={[GUTTER, GUTTER]}>
      <Col md={12}>
        <Feature
          size="medium"
          title={
            <FormattedMessage
              id="app.services.mapping.title"
              defaultMessage="Interactive Web Maps"
            />
          }
          text={
            <FormattedMessage
              id="app.services.mapping.text"
              defaultMessage="We can take your shapefiles, CAD files, regular
                images and more and turn them into intuitive web maps."
            />
          }
          icon={() => <EnvironmentOutlined/>}
          link="/projects/hvammsvirkjun"
          linkText={<FormattedMessage id="services.map.link.text"
                                      defaultMessage="View example map"/>}
        />
      </Col>
      <Col md={12}>
        <Feature
          size="medium"
          title={
            <FormattedMessage
              id="services.dashboards.title"
              defaultMessage="Live Dashboards"
            />
          }
          text={
            <FormattedMessage
              id="services.dashboards.text"
              defaultMessage="Combine data from multiple sources and display in a live
                dashboard, configured to your tastes."
            />
          }
          icon={() => <DashboardOutlined/>}
          link="/projects/quake-dashboard"
          linkText={<FormattedMessage id="services.dashboard.link.text"
                                      defaultMessage="View example dashboard"/>}
        />
      </Col>
      <Col md={12}>
        <Feature
          size="medium"
          title={
            <FormattedMessage
              id="services.dataCollection.title"
              defaultMessage="Data Collection Applications"
            />
          }
          text={
            <FormattedMessage
              id="services.dataCollection.text"
              defaultMessage="Need to collect data from various locations? Have a team working on the same dataset and need input validation, data synchronization, version control or instant calculations? We've got you covered."
            />
          }
          icon={() => <FormOutlined/>}
          link="/projects/sample-collection"
          linkText={<FormattedMessage id="services.collection.link.text"
                                      defaultMessage="View example collection application"/>}
        />
      </Col>
      <Col md={12}>
        <Feature
          size="medium"
          title={
            <FormattedMessage
              id="services.applicationReview.title"
              defaultMessage="Application Review"
            />
          }
          text={
            <FormattedMessage
              id="services.dataCollection.text"
              defaultMessage="Having doubts about the design and impact of your application? Wondering if you're communicating the right message with the correct visualisations? We'll review your application and help you deliver your message"
            />
          }
          icon={() => <FileSearchOutlined/>}
          link={composeMailToUrl({
            subject: "Data application review",
            body: "Hi. I'd like your thoughts on my application..."
          })}
          linkText={"Contact us"}
        />
      </Col>
    </Row>
  </Section>

const ProductsSection = () =>
  <Section id="products">
    <VerticalSplitTitle
      title={
        <FormattedMessage id="products.title" defaultMessage="Products"/>
      }
      text={
        <FormattedMessage
          id="products.text"
          defaultMessage="Your problem might just be solved by one of our carefully designed products"
        />
      }
    />
    <Padding.Vertical px="48"/>
    <Feature.Focus
      // reversed to counter the projects section which has to have the text on the right side
      reverse
      gutter={GUTTER}
      text={
        <FormattedMessage
          id="app.samples.text"
          defaultMessage="<p>EYK can integrate and synchronize all your data and organize it intuitively.</p>
            <p>With EYK you can register your <b>assets</b>, attach <b>samples</b>, <b>time series</b> and <b>well tests</b> to them,
            view the <b>images</b> taken in their vicinity and any <b>seismic activity</b> happening nearby.</p>"
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            i: (...chunks) => <i>{chunks}</i>,
            b: (...chunks) => <b>{chunks}</b>
          }}
        />
      }
      linkText="Visit eyk.is"
      link="https://eyk.is"
      icon={() => <Image src={EYKLogo} backupSrc={EYKLogoBackup}
                         style={{width: "130px"}}/>}
      image={<Screenshot width="800px" src={EYKImage} backupSrc={EYKImageBackup}/>}
    />
    {/*<Padding.Vertical px="94"/>*/}
    {/*<Feature.Focus*/}
    {/*  // reversed to counter the projects section which has to have the text on the right side*/}
    {/*  gutter={GUTTER}*/}
    {/*  text={*/}
    {/*    <FormattedMessage*/}
    {/*      id="app.platform.text"*/}
    {/*      defaultMessage="<p></p><p>EYK combines all our solutions and provides a familiar structure to your data.</p>*/}
    {/*        <p>With EYK you can register your <b>assets</b>, attach <b>samples</b>, <b>time series</b> and <b>well tests</b> to them,*/}
    {/*        view the <b>images</b> taken in their vicinity and any <b>seismic activity</b> happening nearby.</p>"*/}
    {/*      values={{*/}
    {/*        p: (...chunks) => <p>{chunks}</p>,*/}
    {/*        i: (...chunks) => <i>{chunks}</i>,*/}
    {/*        b: (...chunks) => <b>{chunks}</b>*/}
    {/*      }}*/}
    {/*    />*/}
    {/*  }*/}
    {/*  link="https://samples.hugfimi.is"*/}
    {/*  icon={() => <Image src={EYKLogo} backupSrc={EYKLogoBackup} style={{ width: "130px" }} />}*/}
    {/*  image={<Screenshot width="800px" src={EYKImage} backupSrc={EYKImageBackup}/>}*/}
    {/*/>*/}
  </Section>

const Index = () => {
  return [
    <LandingHero/>,
    <ServicesSection/>,
    <ProductsSection/>,
    <ProjectSection className="topology"/>,
    // <Section
    //   id="about-us"
    //   title={
    //     <FormattedMessage id="app.aboutUs.section.title" defaultMessage="About us"/>
    //   }
    // >
    //   <Feature.Focus
    //     gutter={GUTTER}
    //     reverse
    //     title={
    //       <FormattedMessage
    //         id="app.aboutUs.title"
    //         defaultMessage="<i> - We specialize in data - </i>"
    //         values={{
    //           i: (...chunks) => <i>{chunks}</i>
    //         }}
    //       />
    //     }
    //     text={
    //       <FormattedMessage
    //         id="app.aboutUs.text"
    //         defaultMessage="
    //       <p>Our focus is <b>integration</b>, <b>processing</b> and <b>visualization</b> of data on the web.</p>
    //       <p>As well as developing the EYK platform we take on projects of varying scope and dimensions. </p>
    //       <p>Hugfimi was founded in <b>2015</b> and is a subsidiary of Mannvit Engineering.</p>"
    //         values={{
    //           p: (...chunks) => <p>{chunks}</p>,
    //           b: (...chunks) => <b>{chunks}</b>,
    //           h3: (...chunks) => <h3>{chunks}</h3>
    //         }}
    //       />
    //     }
    //     image={
    //       <Image src={HugfimiVerticalLogo} style={{maxWidth: "400px"}}/>
    //     }
    //   />
    // </Section>,
    <TechnologySection />,
    <ClientsSection className="topology" />
  ];
};

export default Index;
