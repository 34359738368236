import styled from "styled-components";

import Image from "./Image";
import React from "react";

const StyledImage = styled(Image)`
  height: intrinsic;   // fixes image height in safari
  max-width: ${props => props.maxWidth};
  width: ${props => props.width ? props.width : "100%"};
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
`;

const Screenshot = ({src, backupSrc, alt, maxWidth, width, ...rest}) => (
  <StyledImage
    src={src}
    backupSrc={backupSrc}
    alt={alt}
    maxWidth={maxWidth}
    width={width}
    {...rest}
  />
)

export default Screenshot;
