import {Route, useHistory, useLocation} from "react-router-dom";
import React, {useEffect, useRef} from "react";
import styled from "styled-components";
import Footer from "./Footer";
import {isEmpty} from "ramda";
import {ScrollSpyManager} from "./ScrollSpyable";
import {scrollToElementWithId} from "../utils/scroll";
import onImagesLoaded from "../utils/onImagesLoaded";
import {SectionContainer} from "./Section";
import {FocusFeatureContainer} from "./Feature";

const TransitioningPage = styled.div`
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: fixed;
  width: 100vw;
  
  & .alternate-background:nth-child(odd) {
    background-color: ${props => props.alternateChildBackgroundColor && "#F7FAFC"};
  }
  
  & > ${SectionContainer}:nth-child(even)  ${FocusFeatureContainer} {
    flex-direction: ${props => props.alternateChildDirection && "row-reverse"};
  }
  
  & .alternate-background:nth-child(2) {
    padding-top: 250px;
  } 
`;

const Page = ({
  path,
  alternateChildDirection,
  alternateChildBackgroundColor,
  children,
  ...props
}) => {
  // connect scrollSpyable elements with react router
  const history = useHistory();
  const scrollSpyChangedHash = useRef(false);

  useEffect(() => {
    ScrollSpyManager.configure({
      offset: 200,
      onSectionEnter: ({hash}, {hash: prevHash}) => {
        scrollSpyChangedHash.current = true;
        history.replace("#" + hash);
      }
    });
  }, [history]);

  // Scroll to section on hash change
  const {hash} = useLocation();

  const didMountRef = useRef(false);
  useEffect(() => {
    if (didMountRef.current) {
      // hash updated, reevaluate scroll position
      if (scrollSpyChangedHash.current === true) {
        // do nothing
        scrollSpyChangedHash.current = false;
        return;
      }

      ScrollSpyManager.ignoreNextScrollEvent = true;
      if (isEmpty(hash)) {
        scrollToElementWithId("pageTop");
      } else {
        const elementId = hash.replace("#", "");
        scrollToElementWithId(elementId);
      }
    } else {
      didMountRef.current = true
    }
  }, [hash]);

  // Initialize scroll based on hash, wait for images to load first
  const initialHash = useRef(hash);
  useEffect(() => {
    onImagesLoaded(() => {
      const elementId = initialHash.current.replace("#", "");
      scrollToElementWithId(elementId);
    });
  }, [initialHash]);

  return (
    <Route path={path} {...props}>
      <TransitioningPage
        className={"page"}
        alternateChildDirection={alternateChildDirection}
        alternateChildBackgroundColor={alternateChildBackgroundColor}
      >
        <div id="pageTop"/>
        <div>
          {children}
          <Footer/>
        </div>
      </TransitioningPage>
    </Route>
  );
};

Page.defaultProps = {
  alternateChildBackgroundColor: true,
  alternateChildDirection: true
};

export default Page;
