import React from "react";
import {FormattedMessage} from "react-intl";
import Hero from "../../components/Hero";
import Screenshot from "../../components/Screenshot";
import Section from "../../components/Section";
import hero from "./screenshots/reinjection.webp";
import heroBackup from "./screenshots/reinjection.png";
import dataIntegration from "./screenshots/dataIntegration.webp";
import dataIntegrationBackup from "./screenshots/dataIntegration.png";
import makeZone from "./screenshots/zone.webp";
import makeZoneBackup from "./screenshots/zone.png";
import {Col, Row} from "antd";
import {GUTTER} from "../../constants";
// import ContactUsButton from "../../components/ContactUsButton";
import styled from "styled-components";
import VerticalSplitTitle from "../../components/VerticalSplitTitle";
import Padding from "../../components/Padding";
import Feature from "../../components/Feature";
import Image from "../../components/Image";

const Text = styled.span`
  line-height: 150%;
  letter-spacing: 1px;
  font-weight: 500;
  font-size: 16px;
`;

const Index = () => {
  return (
    <React.Fragment>
      <Hero
        image={
          <Screenshot
            alt="short video of zooming into injection zone with quakes"
            width="1200px"
            src={hero}
            backupSrc={heroBackup}
          />
        }
        title={
          <FormattedMessage
            id="quakeDashboard.hero.title"
            defaultMessage="Seismic activity dashboard"
          />
        }
        subtitle={
          <FormattedMessage
            id="quakeDashboard.hero.subtitle"
            defaultMessage="This dashboard was created to monitor seismic activity caused
                        by geothermal waste-water re-injection into the reservoir."
          />
        }
      />
      <Section
        id="about-this-project"
        className="topology"
        title={
          <FormattedMessage
            id="quakeDashboard.aboutSection.title"
            defaultMessage="About this project"
          />
        }
      >
        <Row gutter={[GUTTER, GUTTER]}>
          <Col md={12} xs={24}>
            <Text style={{marginBottom: `-${GUTTER / 2}px`}}>
              <FormattedMessage
                id="quakeDashboard.aboutSection.text"
                defaultMessage="
                <p>
                  Our mission here was to help {or} monitor seismic activity caused by
                  re-injection of geothermal wastewater into their reservoirs.
                </p>
                <p>
                  We combine time series from re-injection wells
                  with seismic activity measurements and allow users to compare these across different
                  periods and re-injection zones.
                </p>
                  We produced a web based dashboard which allows users to filter
                  seismic activity by time, depth, magnitude and location. The results
                  are displayed on both charts and a map which allow for easy exploration.
                "
                values={{
                  p: (...chunks) => <p>{chunks}</p>,
                  or: (
                    <a
                      href="https://or.is"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Reykjavík Energy
                    </a>
                  )
                }}
              />
            </Text>
          </Col>
          <Col md={12} xs={24}>
            <Text>
              <FormattedMessage
                id="quakeDashboard.aboutSection.text2"
                defaultMessage="
                <p>
                  To relate re-injection with seismic activity we allow users to define
                  re-injection zones by drawing them as a polygon on the map. The polygon is saved,
                  given a name and once selected the dashboard will only display data
                  located within that polygon.</p>
                  Furthermore, time series
                  displaying temperature, flow and pressure of re-injection into
                  wells located within the polygon are added to the charts,
                  thereby
                  allowing users to compare levels of re-injection to seismic activity.
                "
                values={{
                  p: (...chunks) => <p>{chunks}</p>
                }}
              />
            </Text>
          </Col>
        </Row>
      </Section>
      <Section id="features">
        <VerticalSplitTitle
          title={
            <FormattedMessage
              id="quakeDashboard.features.title"
              defaultMessage="Features"
            />
          }
          text={
            <FormattedMessage
              id="quakeDashboard.features.titleText"
              defaultMessage="The first step is obtaining data!... or is it the design?... The first step is the design! But then it's the data!"
            />
          }
        />
        <Padding.Vertical px="48"/>
        <Feature.Focus
          reverse
          image={
            <Image
              width="700px"
              src={dataIntegration}
              backupSrc={dataIntegrationBackup}
            />
          }
          gutter={GUTTER}
          title={
            <FormattedMessage
              id="quakeDashboard.dataIntegration.title"
              defaultMessage="Data integration"
            />
          }
          text={
            <FormattedMessage
              id="quakeDashboard.dataIntegration.text"
              defaultMessage="Seismic activity in the regions of interest is measured
            by {isor} while re-injection time series can be obtained from {or}'s own
            systems. We use {eyk}'s data integration capabilities to make connections
            to both datasets and configure them to run every 30 minutes to obtain the
            newest data."
              values={{
                p: (...chunks) => <p>{chunks}</p>,
                isor: (
                  <a
                    href="https://en.isor.is"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    ÍSOR
                  </a>
                ),
                eyk: (
                  <a
                    href="https://eyk.hugfimi.is"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    EYK
                  </a>
                ),
                or: (
                  <a
                    href="https://or.is"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Reykjavík Energy
                  </a>
                )
              }}
            />
          }
        />
        <Feature.Focus
          image={
            <Screenshot width="800px" src={makeZone} backupSrc={makeZoneBackup}/>
          }
          gutter={GUTTER}
          title={
            <FormattedMessage
              id="quakeDashboard.dataIntegration.title"
              defaultMessage="Custom re-injection zones"
            />
          }
          text={
            <FormattedMessage
              id="quakeDashboard.dataIntegration.text"
              defaultMessage="Users can create their own zones to monitor. Selecting
              a zone will limit results to those located within the zone and add
              re-injection time series from wells within that zone"
            />
          }
        />
      </Section>
      {/*<ContactUsButton*/}
      {/*  subject="Hugfimi.is: Live dashboard"*/}
      {/*  body="I'd like to hear more about your live dashboard services. Are you available for a call?"*/}
      {/*  colorless={false}*/}
      {/*>*/}
      {/*  Contact us*/}
      {/*</ContactUsButton>*/}
    </React.Fragment>
  );
};

export default Index;
