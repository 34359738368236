import React from "react";
import isNil from "../utils/isNil";
import styled from "styled-components";
import logoHollowSideWebp from "../images/Hugfimi-hollow-side.webp";
import logoHollowSide from "../images/Hugfimi-hollow-side.png";
import Image from "./Image";
import {composeMailToUrl} from "./ContactUsButton";
import Section from "./Section";
import {Col, Row} from "antd";
import {GUTTER} from "../constants";
import {Link} from "react-router-dom";


const data = {
  logo: (
    <Image
      src={logoHollowSideWebp}
      backupSrc={logoHollowSide}
      alt="hugfimi logo"
      height="64px"
    />
  ),
  address: [
    <span>
      <span>Urðarhvarf 6, 203 Kópavogur</span>
      <br/>
      <span>Kaupangi, 600 Akureyri</span>
      <br/>
      <span>Iceland</span>
      <br/>
    <a
      href={composeMailToUrl({
        subject: "Hi Hugfimi",
        body: "I'd like to discuss a project, are you available for a call?"
      })}
      target="_blank"
      rel="noopener noreferrer"
    >
      hugfimi@hugfimi.is
    </a>
    </span>
  ],
  contact: (
    <a
      href={composeMailToUrl({
        subject: "Hi Hugfimi",
        body: "I'd like to discuss a project, are you available for a call?"
      })}
      target="_blank"
      rel="noopener noreferrer"
    >
      hugfimi@hugfimi.is
    </a>
  ),
  sections: [
    {
      title: {text: "SERVICES", to: {pathname: "/", hash: "services"}},
      links: [
        {
          to: {pathname: "/projects/hvammsvirkjun", hash: ""},
          text: "Interactive Web Map"
        },
        {
          to: {pathname: "/projects/quake-dashboard", hash: ""},
          text: "Live Data Dashboards"
        },
        {
          to: {pathname: "/projects/sample-collection", hash: ""},
          text: "Data Collection"
        }
      ]
    },
    {
      title: {text: "PRODUCTS", to: {pathname: "", hash: "products"}},
      links: [{href: "https://eyk.hugfimi.is", text: "EYK"}]
    },
    {
      title: {text: "PROJECTS", to: {pathname: "", hash: "projects"}},
      links: [
        {
          to: {pathname: "/projects/hvammsvirkjun", hash: ""},
          text: "Environmental impact"
        },
        {
          to: {pathname: "/projects/quake-dashboard", hash: ""},
          text: "Seismic activity"
        },

        {
          to: {pathname: "/projects/sample-collection", hash: ""},
          text: "Sample collection"
        }
      ]
    }
  ]
};

const FooterSectionTitle = styled.h2`
  font-weight: 500;
  color: rgb(26, 32, 44);
  letter-spacing: .1em;
  font-size: .875rem;
  margin-bottom: .75rem;
`;

const FooterSectionContent = styled.nav`
  list-style-type: none;

  & li {
    margin-bottom: 4px;
  }

  & a {
    color: rgb(113, 128, 150);
  }

  & a:hover {
    color: rgb(45, 55, 72)
  }
`;

const Footer = ({logo, address, sections}) => {
  return (
    <footer>
      <Section>
        <Row gutter={[GUTTER, GUTTER]}>
          <Col md={12} xs={24} lg={9}>
            <div style={{marginBottom: "24px"}}>
              {logo}
            </div>
            <p>{address}</p>
          </Col>
          {sections.map(section => (
            <Col md={12} xs={24} lg={5}>
              <FooterSectionTitle>
                <Link to={{...section.title.to}}>{section.title.text}</Link>
              </FooterSectionTitle>
              <FooterSectionContent>
                {section.links.map(link => (
                  <li>
                    {isNil(link.to) ? (
                      <a href={link.href} rel="noopener noreferrer" target="_blank">
                        {link.text}
                      </a>
                    ) : (
                      <Link to={{...link.to}}>{link.text}</Link>
                    )}
                  </li>
                ))}
              </FooterSectionContent>
            </Col>
          ))}
        </Row>
      </Section>
    </footer>
    // <footer className="footer alternate-background">
    //   <div className="main-container">
    //     <div className="footer-logo-container">
    //       <span className="footer-logo">{logo}</span>
    //     </div>
    //     <div className="footer-content-container">
    //       <p className="footer-logo-address">{address}</p>
    //       {/*{sections.map(section => (*/}
    //       {/*  <div className="footer-content-col">*/}
    //       {/*    <h2 className="footer-content-col-title">{section.title}</h2>*/}
    //       {/*    <nav className="footer-content-col-nav">*/}
    //       {/*      {section.links.map(link => (*/}
    //       {/*        <li>*/}
    //       {/*          <a href={link.href} className="footer-content-col-li">*/}
    //       {/*            {link.description}*/}
    //       {/*          </a>*/}
    //       {/*        </li>*/}
    //       {/*      ))}*/}
    //       {/*    </nav>*/}
    //       {/*  </div>*/}
    //       {/*))}*/}
    //     </div>
    //   </div>
    // </footer>
  );
};

Footer.defaultProps = {
  ...data
};

export default Footer;
