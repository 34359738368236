import styled from "styled-components";

const Flex = styled.div`
  display: flex;
  align-content: ${props => props.alignContent};
  align-items: ${props => props.alignItems};
  justify-content: ${props => props.justifyContent};
  justify-items: ${props => props.justifyItems};
  flex: ${props => props.flex};
  padding: ${props => props.padding};
  margin: ${props => props.margin};
`;

const Row = styled(Flex)`
  flex-direction: row;
`;

const Column = styled(Flex)`
  flex-direction: column;
`;

Flex.Row = Row;
Flex.Column = Column;

export default Flex;
