import React from "react";
import Section from "./Section";
import {FormattedMessage} from "react-intl";
import Feature from "./Feature";
import Margin from "./Margin";
import ContactUsButton from "./ContactUsButton";
import Image from "./Image";

import WIP from "../images/work_in_progress.svg";

const WorkInProgressSection = ({aboutSubject, aboutBody}) => {
  return (
    <Section
      id="Workinprogress"
      title={
        <FormattedMessage
          id="app.sections.workInProgress"
          defaultMessage="Work in progress"
        />
      }
    >
      <Feature.Focus
        textColProps={{lg: 8, xs: 24}}
        imageColProps={{lg: 16, xs: 24}}
        image={
          <Image src={WIP} width="600px"/>
        }
        title={
          <FormattedMessage
            id="app.sections.workInProgress.title"
            defaultMessage="Still in development"
          />
        }
        text={
          <FormattedMessage
            id="app.sections.workInProgress.text"
            defaultMessage="
            <p>
              This page is still in development. For more information about the subject
              contact us
            </p>"
            values={{
              p: (...chunks) => <p>{chunks}</p>
            }}
          />
        }
      >
        <Margin bottom="16px"/>
        <ContactUsButton subject={aboutSubject} body={aboutBody}>
          <FormattedMessage id="app.sections.workInProgress.contactUs.title"
                            defaultMessage="Contact us"/>
        </ContactUsButton>
      </Feature.Focus>
    </Section>
  );
};

export default WorkInProgressSection;