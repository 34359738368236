import React from "react";

const Image = ({alt, src, backupSrc, ...rest}) => (
  <img
    alt={alt}
    src={src}
    onError={e => {
      e.target.onerror = null;
      e.target.src = backupSrc;
    }}
    {...rest}
  />
)


export default Image;
