import React from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {ArrowRightOutlined, MailOutlined} from "@ant-design/icons";
import {Col, Row} from "antd";
import {FormattedMessage} from "react-intl";
import Margin from "./Margin";
import {GUTTER} from "../constants";

const FeatureContainer = styled.div``;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  line-height: 2rem;
  font-weight: 500;
`;

const Title = styled.span`
  margin-bottom: 0.5em;
`;

const IconContainer = styled(Title)`
  color: #ca4d27;
  margin-right: 8px;
`;

const Text = styled.span`
  font-size: ${props => props.size === "large" && "1rem"};
  font-size: ${props => props.size === "medium" && "1rem"};
`;

const MoreLink = styled.div`
  font-size: 1rem;
  & .anticon {
    transition: all 350ms linear;
  }
  & .anticon-arrow-right{
    margin-left: 8px;
  }
  & .anticon-mail {
    margin-right: 8px;
  }

  &:hover *,
  &:focus * {
    text-decoration: underline;
  }
  &:hover .anticon-arrow-right,
  &:focus .anticon-arrow-right {
    margin-left: 12px;
  }
  
  &:hover .anticon-mail,
  &:focus .anticon-mail {
    transform: rotate(15deg);
  }
`;

const FeatureButton = ({link, text}) => {
  if (link.startsWith("mailto")) {
    return (
      <MoreLink>
        <a href={link} target="_blank" rel="noopener noreferrer">
          <MailOutlined/>
          {text}
        </a>
      </MoreLink>
    );
  } else if (link.startsWith("http")) {
    return (
      <MoreLink>
        <a href={link} target="_blank" rel="noopener noreferrer">
          {text}
          <ArrowRightOutlined/>
        </a>
      </MoreLink>
    );
  } else {
    return (
      <MoreLink>
        <Link to={link}>
          {text}
          <ArrowRightOutlined/>
        </Link>
      </MoreLink>
    );
  }
};

const Feature = ({
                   icon,
                   size,
                   title,
                   text,
                   link,
                   linkText,
                   children,
                   ...rest
                 }) => {
  return (
    <FeatureContainer {...rest}>
      <TitleContainer>
        {icon && <IconContainer>{icon()}</IconContainer>}
        {title && <Title>{title}</Title>}
      </TitleContainer>
      <Text size={size}>{text}</Text>
      {link && (
        <React.Fragment>
          <Margin bottom="16px"/>
          <FeatureButton
            link={link}
            text={
              linkText || (
                <FormattedMessage
                  id="feature.more"
                  defaultMessage="Learn more"
                />
              )
            }
          />
        </React.Fragment>
      )}
      {children}
    </FeatureContainer>
  );
};

const OverflowingCol = styled(Col)`
  display: flex;
  overflow: visible;
  justify-content: ${props => (props.reverse ? "flex-start" : "flex-end")};
`;
const ImageContainer = styled.div``;

export const FocusFeatureContainer = styled(Row)`
  margin-bottom: ${props => `-${props.gutter[0]}px`}!important;
  flex-direction: ${props => props.reverse && "row-reverse"};
  flex-wrap: wrap-reverse;
`;

const Focus = ({
                 image,
                 icon,
                 title,
                 text,
                 link,
                 linkText,
                 gutter,
                 imageColProps,
                 textColProps,
                 children,
                 reverse,
                 ...rest
               }) => {
  return (
    <FocusFeatureContainer
      gutter={[gutter, gutter]}
      reverse={reverse}
      {...rest}
    >
      <OverflowingCol {...imageColProps} reverse={reverse}>
        <ImageContainer>{image}</ImageContainer>
      </OverflowingCol>
      <OverflowingCol {...textColProps}>
        <Feature
          size="large"
          icon={icon}
          title={title}
          text={text}
          link={link}
          linkText={linkText}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center"
          }}
        >
          {children}
        </Feature>
      </OverflowingCol>
    </FocusFeatureContainer>
  );
};

Focus.defaultProps = {
  imageColProps: {
    md: 12,
    xs: 24
  },
  textColProps: {
    md: 12,
    xs: 24
  },
  gutter: GUTTER
};

Feature.Focus = Focus;

export default Feature;
