import styled from "styled-components";

const Separator = styled.div`
  border-top: #CA4D27 2px solid;
  height: 24px;
  width: 100px;
  margin: 15px;
`;

Separator.Vertical = styled.div`
  border-left: #ca4d27 2px solid;
  height: 100px;
  width: 24px;
  margin: 15px;
`

export default Separator;
